<template>
  <div class="bg-main training vh-100">
    <v-container class="py-0 px-0" fluid>
      <v-alert
        v-if="!$online"
        dense
        tile
        color="gray"
        icon="mdi-alert-circle-outline"
        class="alert-offline text-headline"
      >
        {{ $t("t_no_connection") }}
      </v-alert>
      <v-card flat class="py-0" height="100%" tile v-if="!loading">
        <v-card-text class="d-flex pb-2">
          <v-btn :to="{ name: 'training' }" class="btn-secondary mx-0 mt-0" small
            ><v-icon>mdi-chevron-left</v-icon>{{ $t("t_back") }}</v-btn
          >
        </v-card-text>
        <div v-if="asset" class="mt-0 mx-4">
          <div class="text-right">
            <v-btn icon small @click="asset = null" color="secondary"><v-icon>mdi-close-box</v-icon></v-btn>
          </div>
          <vue-pdf-embed v-if="asset == 'pdf'" ref="pdf" :source="pdfSource" :scale="1" />
          <video
            v-if="asset == 'video'"
            ref="videoPlayer"
            :src="videoSrc"
            controls
            autoplay="true"
            preload="metadata"
            width="100%"
            @error="reloadVideoAsset"
          ></video>
          <img v-if="asset == 'image'" ref="imgAsset" :src="imgSource" @error="reloadImageAsset" />
        </div>
        <div v-else class="mt-2 mx-4">
          <v-img v-if="courseHeaderUrl" width="100%" :src="courseHeaderUrl"></v-img>
        </div>
        <v-list-item class="pt-0" style="margin-top: -10px; margin-bottom: -10px">
          <v-list-item-icon class="mr-4 ml-auto">
            <v-icon class="pt-2" v-if="courseDetails.format === 'On-Demand'" color="secondary" x-large
              >mdi-television-play</v-icon
            >
            <v-icon class="pt-2" v-else-if="courseDetails.format === 'Webinar'" color="secondary" x-large
              >mdi-monitor-eye</v-icon
            >
          </v-list-item-icon>
          <v-list-item-content class="ml-2 mb-0">
            <v-list-item-title class="text-h5">{{ courseDetails.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-card-text class="py-0 mb-0 mt-2" v-if="examSuccessAttempt?.passed">
          <v-alert color="success font-weight-bold" dense>
            <div>{{ $t("v_training.t_course_completed") }} {{ formatDateFull(examSuccessAttempt.submittedOn) }}</div>
          </v-alert>
        </v-card-text>
        <v-card-actions class="px-4 pb-4" v-if="!enrollmentDetails?.enrolledOn || enrollmentDetails.endedOn">
          <div>
            <v-btn color="primary" class="text-right mb-2 rounded" @click.stop="enroll()">
              {{ $t("v_training.t_enroll_btn") }}
            </v-btn>
          </div>
        </v-card-actions>
        <v-card-text>
          <v-row>
            <v-col>
              <v-row>
                <v-col>
                  <v-list-item-subtitle class="subheadline-text">{{
                    $t("v_training.t_short_code").toUpperCase()
                  }}</v-list-item-subtitle>
                  <v-list-item-title>{{ courseDetails.shortCode }}</v-list-item-title>
                </v-col>
                <v-col>
                  <v-list-item-subtitle class="subheadline-text">{{
                    $t("v_training.t_format").toUpperCase()
                  }}</v-list-item-subtitle>
                  <v-list-item-title>{{ courseDetails.format }}</v-list-item-title>
                </v-col>
              </v-row>
            </v-col>
            <v-col>
              <v-row>
                <v-col>
                  <v-list-item-subtitle class="subheadline-text">{{
                    $t("v_training.t_enrolled").toUpperCase()
                  }}</v-list-item-subtitle>
                  <v-list-item-title>{{
                    enrollmentDetails?.enrolledOn ? formatDateFull(enrollmentDetails.enrolledOn) : "NA"
                  }}</v-list-item-title>
                </v-col>

                <v-col>
                  <v-list-item-subtitle class="subheadline-text">{{
                    $t("v_training.t_due").toUpperCase()
                  }}</v-list-item-subtitle>
                  <v-list-item-title>{{ formatDateFull(courseDetails.expiresOn) }}</v-list-item-title>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="py-3">
            <v-col sm="3" cols="6">
              <v-row>
                <v-col>
                  <v-list-item-subtitle class="subheadline-text">{{
                    $t("v_training.t_duration").toUpperCase()
                  }}</v-list-item-subtitle>
                  <v-list-item-title
                    >{{ courseDetails.expectedCourseDurationMinutes }}
                    {{ $t("v_training.t_minutes") }}</v-list-item-title
                  >
                </v-col>
              </v-row>
            </v-col>
            <v-col sm="3" cols="6" v-if="courseDetails.credits.length > 0">
              <v-row>
                <v-col>
                  <v-list-item-subtitle class="subheadline-text">{{
                    $t("v_training.t_credit_amount").toUpperCase()
                  }}</v-list-item-subtitle>
                  <div v-for="(credit, i) in courseDetails.credits" :key="i" class="ml-auto">
                    <v-list-item-title>{{ credit.creditTopicName }}: {{ credit.credits }} </v-list-item-title>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="pt-0">
            <v-col>
              <v-list-item-title class="mt-5 font-weight-bold">{{ $t("v_training.t_description") }}:</v-list-item-title>
              <p class="mb-0">{{ courseDetails.description }}</p>
            </v-col>
          </v-row>
        </v-card-text>
        <template v-if="courseDetails.format === 'Webinar'">
          <div>
            <WebinarSessions ref="trainingListItem" :parent="'trainingCourse'" @getCert="getCertificate" />
          </div>
        </template>
        <template v-if="assets && assets.length">
          <v-card-title class="pb-1 mt-4">{{ $t("v_training.t_course_materials") }}</v-card-title>
          <v-card-text class="pt-0">
            <v-row
              class="mx-0 mt-2 rounded-5"
              :style="
                $vuetify.theme.dark ? 'background-color: #212121 !important' : 'background-color: #f3f3f3 !important'
              "
              v-for="asset in assets"
              :key="asset.id"
            >
              <v-col cols="12" md="7">
                <v-icon class="mr-3" style="float: left; left: -1px" large color="gray lighten-1">{{
                  mimeIcon(asset.mimeType)
                }}</v-icon
                ><span
                  class="font-weight-bold"
                  style="top: 7px; position: relative; cursor: pointer"
                  @click="viewAsset(asset)"
                  >{{ asset.originalFileName | truncate(70) }}</span
                >
              </v-col>
              <v-col cols="12" md="5" class="pt-1" :class="$vuetify.breakpoint.mdAndUp ? 'text-right pt-4' : ''">
                <v-btn small color="primary" class="mr-4" @click="viewAsset(asset)">
                  {{ getBtnText(asset.mimeType) }}
                </v-btn>
                <v-btn small @click="downloadAssetFile(asset)">
                  <v-icon small class="mr-2">mdi-download</v-icon> Download
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </template>
        <template v-if="courseDetails.instructors && courseDetails.instructors.length">
          <v-card-title class="pb-1">{{ $t("v_training.t_instructors") }}</v-card-title>
          <v-card-text class="">
            <v-row v-for="instructor in courseDetails.instructors" :key="instructor.id">
              <v-col>
                <v-list-item-title class="font-weight-bold">{{ instructor.name }}</v-list-item-title>
                <p class="mb-0">
                  <span class="font-weight-bold">{{ $t("v_training.t_email") }}</span
                  >: {{ instructor.email }}
                </p>
                <p class="mb-0">
                  <span class="font-weight-bold">{{ $t("v_training.t_bio") }}</span
                  >: {{ instructor.biography }}
                </p>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider class="ma-3"></v-divider>
        </template>

        <v-card-actions class="pt-0" v-if="examSuccessAttempt?.passed">
          <div class="mx-2">
            <h3 class="my-2">Certificate of Completion</h3>
            <v-btn
              :outlined="$vuetify.theme.dark"
              color="primary"
              small
              class="text-right rounded mb-2 mr-2"
              @click.stop="showCertificate()"
            >
              View
            </v-btn>

            <v-btn
              :outlined="$vuetify.theme.dark"
              small
              color="primary"
              class="text-right rounded mb-2 mr-2"
              @click.stop="downloadCertificate()"
            >
              {{ $t("t_download") }}
            </v-btn>

            <v-btn
              v-if="$platform != 'web'"
              :outlined="$vuetify.theme.dark"
              small
              color="primary"
              class="text-right rounded mb-2"
              @click.stop="shareCertificate()"
            >
              Share
            </v-btn>
          </div>
        </v-card-actions>

        <template v-if="courseDetails.hasExam && enrollmentDetails && !enrollmentDetails.endedOn">
          <v-card-text style="font-size: unset"
            ><h3 class="white--text">{{ $t("v_training.t_course_exam") }}</h3></v-card-text
          >
          <v-card-text class="pt-0">
            <TrainingExamSummary :course="courseDetails" ref="trainingexamsummary"></TrainingExamSummary>
          </v-card-text>
        </template>
        <div class="cert-container" v-if="enrollmentDetails">
          <div id="certificate">
            <h1 id="userName">{{ enrollmentDetails.user?.friendlyName }}</h1>
            <h1 id="courseTitle">{{ courseDetails.title }}</h1>
            <h2 id="passed">{{ formatDateAbbrMonthDayYear(enrollmentDetails.enrolledOn) }}</h2>
            <div id="instructor">
              <div v-for="(instructor, i) in courseDetails.instructors" :key="i">
                <div>
                  <h2 class="instructor-font d-block">{{ instructor.name }}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import Helpers from "@/mixins/helpers";
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";
import WebinarSessions from "@/components/training/WebinarSessions.vue";
import { mapActions } from "vuex";
import TrainingExamSummary from "@/components/training/TrainingExamSummary.vue";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { Filesystem, Directory } from "@capacitor/filesystem";
import { Share } from "@capacitor/share";

export default {
  name: "TrainingCourse",
  mixins: [Helpers],
  components: { VuePdfEmbed, WebinarSessions, TrainingExamSummary },
  computed: {},
  watch: {},
  data: () => ({
    loading: false,
    examSuccessAttempt: null,
    assets: [],
    enrollmentDetails: {},
    courseDetails: {},
    pdfSource: "",
    asset: "",
    videoSrc: "",
    imgSource: "",
    courseHeaderUrl: "",
    trainingId: null,
    activeAsset: null,
  }),

  methods: {
    ...mapActions("training", [
      "getCourse",
      "getCourseAssets",
      "createUserDownloadToken",
      "downloadAsset",
      "startEnrollment",
      "cancelEnrollment",
      "getEnrollmentDetails",
      "getAttempts",
    ]),

    async fetchAssets(key, courseId, assetId) {
      return new Promise((resolve) => {
        this.createUserDownloadToken({ key, courseId }).then((result) => {
          const res = result.data;
          const tokenParams = {
            assetId: assetId,
            token: res.assetDownloadToken,
          };
          this.downloadAsset(tokenParams).then((r) => {
            resolve(r.data);
          });
        });
      });
    },

    async getAssets() {
      let assetParams = {
        courseId: this.trainingId,
        "paging.orderbyfield": "id",
        "paging.ascending": false,
        "paging.skipnum": 0,
        "paging.takenum": 9999,
      };
      const r = await this.getCourseAssets(assetParams);
      const userAssets = r.data;
      try {
        const assetPromises = userAssets.map(({ key, courseId, assetId }) => this.fetchAssets(key, courseId, assetId));
        this.assets = await Promise.all(assetPromises);
      } catch (error) {
        console.log("Error occurred:", error);
      }
    },

    async getTrainingCourse() {
      this.loading = true;
      this.showLoadBar();

      try {
        const course = await this.getCourse(this.trainingId);
        this.courseDetails = course.data;

        this.getAssets();
        this.getEnrollment();

        if (
          this.courseDetails.headerImageAssetId &&
          this.courseDetails.headerImageAssetPublicId !== "00000000-0000-0000-0000-000000000000"
        ) {
          this.courseHeaderUrl = await this.getImageAsset(
            this.courseDetails.headerImageAssetId,
            this.courseDetails.headerImageAssetPublicId,
          );
        }

        this.loading = false;
        this.hideLoadBar();
      } catch {
        this.loading = false;
        this.hideLoadBar();
      }
    },

    async enroll() {
      this.startEnrollment(this.courseDetails.id);
      this.responseMessage("Successfully enrolled in course", 5000);
      this.getTrainingCourse();
    },

    async getEnrollment() {
      let params = {
        "paging.ascending": false,
        "paging.skipnum": 0,
        "paging.takenum": 9999,
        "paging.orderbyfield": "enrolledOn",
        courseId: this.courseDetails.id,
      };

      this.enrollmentDetails = await this.getEnrollmentDetails(params);
      if (!this.enrollmentDetails) return;

      const a = await this.getAttempts({ courseEnrollmentId: this.enrollmentDetails.id });
      const attempts = await a.data;
      this.examSuccessAttempt = attempts.find((a) => a.passed);
    },

    //for the case of expired requests
    async reloadVideoAsset() {
      this.videoSrc = "";
      await this.$nextTick();
      await this.getAssets();
      const newAsset = this.assets.find((a) => a.publicId == this.activeAsset.publicId);
      this.videoSrc = newAsset?.downloadUrl;
      const videoElement = this.$refs.videoPlayer;
      videoElement.load();
    },

    async reloadImageAsset() {
      this.imgSource = "";
      await this.$nextTick();
      await this.getAssets();
      const newAsset = this.assets.find((a) => a.publicId == this.activeAsset.publicId);
      this.imgSource = newAsset?.downloadUrl;
    },

    async viewAsset(item) {
      console.log("view asset");
      this.showLoadBar();
      try {
        this.activeAsset = item;
        //video loads download url with expiration to utilize progressive download - reloads on expiration (5 min: set in API)
        if (item.mimeType?.includes("video")) {
          this.asset = "video";
          this.videoSrc = item.downloadUrl;
        } else {
          //files load blobs and utilize caching system
          const blob = await this.getAssetFromDownloadUrl(item.downloadUrl, item.publicId, item.cacheable);
          if (item.mimeType == "application/json") {
            fetch(blob)
              .then((response) => response.json())
              .then((data) => {
                const url = data.url;
                if (this.$platform !== "web") {
                  window.location = url;
                } else {
                  window.open(url, "_blank");
                }
              });
          } else if (item.mimeType?.includes("pdf")) {
            this.pdfSource = blob;
            this.asset = "pdf";
          } else if (item.mimeType?.includes("image")) {
            this.imgSource = item.downloadUrl;
            this.asset = "image";
          } else {
            if (this.$platform !== "web") {
              window.location = item.downloadUrl;
            } else {
              window.open(blob, "_blank");
            }
          }
        }
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        this.hideLoadBar();
      } catch {
        this.hideLoadBar();
      }
    },

    async downloadAssetFile(item) {
      this.showLoadBar();
      try {
        const downloadUrl = await this.getAssetFromDownloadUrl(item.downloadUrl, item.publicId, item.cacheable);
        if (item.mimeType == "application/json") {
          fetch(downloadUrl)
            .then((response) => response.json())
            .then((data) => {
              const url = data.url;
              if (this.$platform !== "web") {
                window.location = url;
              } else {
                window.open(url, "_blank");
              }
            });
        } else {
          const url = item.downloadUrl ? item.downloadUrl : downloadUrl;
          if (this.$platform !== "web") {
            window.location = url;
          } else {
            window.open(url, "_blank");
          }
        }
        this.hideLoadBar();
      } catch {
        this.hideLoadBar();
      }
    },

    async showCertificate() {
      try {
        const pdfjs = document.querySelector("#certificate");
        if (!pdfjs) {
          console.error("Certificate element not found");
          return;
        }

        // Make visible and wait for render
        pdfjs.style.display = "block";
        await new Promise((resolve) => setTimeout(resolve, 100)); // Wait for render

        // Ensure background image is loaded
        const bgImg = new Image();
        bgImg.src = require("@/components/training/cert-acrisure-safety.jpg");
        await new Promise((resolve, reject) => {
          bgImg.onload = resolve;
          bgImg.onerror = reject;
        });

        try {
          const canvas = await html2canvas(pdfjs, {
            scale: 1.5,
            useCORS: true,
            allowTaint: true,
            backgroundColor: null,
            logging: true, // Temporarily enable for debugging
            onclone: (clonedDoc) => {
              const clonedElement = clonedDoc.querySelector("#certificate");
              if (clonedElement) {
                clonedElement.style.display = "block";
                clonedElement.style.visibility = "visible";
              }
            },
          });

          const imgData = canvas.toDataURL("image/jpeg", 1.0);
          const pdf = new jsPDF({
            orientation: "landscape",
            unit: "px",
            format: [canvas.width, canvas.height],
          });

          pdf.addImage(imgData, "JPEG", 0, 0, canvas.width, canvas.height);
          const pdfBlobUrl = URL.createObjectURL(pdf.output("blob"));
          this.pdfSource = pdfBlobUrl;
          this.asset = "pdf";
        } catch (canvasError) {
          console.error("Canvas generation error:", canvasError);
          throw canvasError;
        } finally {
          pdfjs.style.display = "none";
        }
      } catch (error) {
        console.error("Certificate generation error:", error);
        this.responseMessage("Error generating certificate", 5000, "error");
      }
    },

    async downloadCertificate(val) {
      try {
        const pdfjs = document.querySelector("#certificate");
        pdfjs.style.display = "block";
        const canvas = await html2canvas(pdfjs, { scale: 1.5 });
        const imgData = canvas.toDataURL("image/jpeg", 1.0); // Correct quality value
        let pdf = new jsPDF({
          orientation: "landscape",
          unit: "px",
        });
        const width = pdf.internal.pageSize.getWidth();
        const height = pdf.internal.pageSize.getHeight();
        pdf.addImage(imgData, "JPEG", 0, 0, width, height);
        const fileName = this.certificateFileName(val);
        pdfjs.style.display = "none";
        if (this.$platform !== "web") {
          const pdfOutput = pdf.output("blob");
          const base64data = await this.convertBlobToBase64(pdfOutput);
          await Filesystem.writeFile({
            path: fileName,
            data: base64data,
            directory: Directory.Documents,
          });
        } else {
          pdf.save(fileName);
        }
        this.responseMessage("Certificate successfully downloaded", 5000);
      } catch (error) {
        this.responseMessage("Error downloading certificate", 5000, "error");
      }
    },

    async shareCertificate(val) {
      const pdfjs = document.querySelector("#certificate");
      try {
        pdfjs.style.display = "block";
        const canvas = await html2canvas(pdfjs, { scale: 1.5 });
        const imgData = canvas.toDataURL("image/jpeg", 1.0);
        const pdf = new jsPDF({
          orientation: "landscape",
          unit: "px",
        });
        const width = pdf.internal.pageSize.getWidth();
        const height = pdf.internal.pageSize.getHeight();
        pdf.addImage(imgData, "JPEG", 0, 0, width, height);
        const fileName = this.certificateFileName(val);
        const blob = pdf.output("blob");
        await this.sharePdf(blob, fileName);
      } catch (error) {
        console.error("Error sharing certificate", error);
      } finally {
        pdfjs.style.display = "none";
      }
    },

    async sharePdf(pdfBlob, fileName) {
      try {
        const base64data = await this.convertBlobToBase64(pdfBlob);
        await Filesystem.writeFile({
          path: fileName,
          data: base64data,
          directory: Directory.Cache,
        });
        const fileUri = await Filesystem.getUri({
          path: fileName,
          directory: Directory.Cache,
        });
        await Share.share({
          title: "Share Training Certificate",
          text: "Certificate of completion",
          url: fileUri.uri,
          dialogTitle: "Share Certificate",
        });
      } catch (error) {
        console.error("Error in sharing PDF", error);
      }
    },

    convertBlobToBase64(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
      });
    },

    certificateFileName(val) {
      const datePassed = this.examSuccessAttempt?.submittedOn ? this.examSuccessAttempt?.submittedOn : val?.passedOn;
      let name = "certificate.pdf";
      if (this.formatDateMonthYear(datePassed)) {
        name = this.formatDateMonthYear(datePassed) + "_" + name;
      }
      if (this.courseDetails.title) {
        name = this.courseDetails.title.replace(/ /g, "_")?.toLowerCase() + "_" + name;
      }
      return name;
    },

    getBtnText(mimeType) {
      switch (mimeType) {
        case "application/pdf":
          return this.$t("v_training.t_read_now");
        case "video/mp4":
          return this.$t("v_training.t_watch_now");
        case "image/png":
          return this.$t("v_training.t_view_now");
        case "image/jpg":
          return this.$t("v_training.t_view_now");
        case "image/jpeg":
          return this.$t("v_training.t_view_now");
        case "audio":
          return "Listen Now";
        case "application/json":
          return this.$t("v_training.t_read_now");
        default:
          return this.$t("v_training.t_view_now");
      }
    },

    mimeIcon(mimeType) {
      if (mimeType?.includes("video")) {
        mimeType = "video";
      }
      if (mimeType?.includes("image")) {
        mimeType = "image";
      }
      if (mimeType?.includes("audio")) {
        mimeType = "audio";
      }
      switch (mimeType) {
        case "application/pdf":
          return "mdi-file-pdf-box";
        case "video":
          return "mdi-video-box";
        case "image":
          return "mdi-image";
        case "audio":
          return "mdi-volue-high";
        case "application/json":
          return "mdi-link-box-variant";
        default:
          return "mdi-open-in-new";
      }
    },
  },

  created() {
    if (this.$route.params.id) {
      this.trainingId = this.$route.params.id;
    } else {
      this.$router.go(-1);
    }

    this.getTrainingCourse();
  },
};
</script>
<style lang="scss">
.training {
  &.bg-main {
    background: #1d1d1d !important;
  }

  .v-window__prev,
  .v-window__next {
    top: 15px;
    z-index: 999;
  }
  .paginate {
    position: relative;
    top: -57px;
    background: rgba(0, 0, 0, 0.5);
    padding: 7px;
  }
  .cert-container {
    position: relative;
    width: 1754px;
  }
  #certificate {
    display: none;
    height: 1240px;
    width: 1754px;
    background-image: url("../components/training/cert-acrisure-safety.jpg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    color: black;
  }

  #userName {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 42px;
    transform: translate(-50%, -50%);
  }

  #courseTitle {
    font-size: 40px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 62%;
  }

  #passed {
    position: absolute;
    top: 77%;
    left: 33%;
    font-size: 32px;
    transform: translate(-50%, -50%);
  }

  #instructor {
    position: absolute;
    top: 77%;
    left: 66%;
    v-align: bottom;
    transform: translate(-50%, -50%);
  }
  .instructor-font {
    font-size: 32px;
  }
}
</style>
